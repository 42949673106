import React from "react"
import { Provider } from "react-redux"

import store from "./src/store"
import * as muiTheme from "./src/muiTheme"
import { ThemeProvider } from "@material-ui/styles"
import { createTheme } from "@material-ui/core/styles"

type Props = {
  children: React.ReactNode
}
const Main: React.FC<Props> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

// Create a theme instance.
const theme = createTheme(muiTheme.theme)

export default ({ element }) => {
  return (
    <Provider store={store}>
      <Main>{element}</Main>
    </Provider>
  )
}
